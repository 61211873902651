<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5 ml-3">Centro de Custo</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form action="#" method="POST" autocomplete="off">
        <div class="px-5 pb-4">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-6">
              <label for="name" class="block text-sm font-medium">Nome</label>
              <input
                v-model="form.nome"
                type="text"
                name="name"
                id="name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-6">
              <label for="descricao" class="block text-sm font-medium">Descrição</label>
              <textarea
                v-model="form.descricao"
                rows="4"
                type="text"
                name="descricao"
                id="descricao"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <div class="col-span-12 md:col-span-3">
              <label for="limiteMensal" class="block text-sm font-medium mb-3">
                <input
                  type="checkbox"
                  v-model="form.limiteMensal.ativo"
                  class="rounded-sm"
                  id="limiteMensal"
                />
                <span class="ml-2"> Limite de envio mensal </span>
              </label>
              <div v-if="form.limiteMensal.ativo">
                <label for="volumeLimite" class="block text-sm font-medium">Volume máximo</label>
                <input
                  v-model="form.limiteMensal.volume"
                  type="number"
                  name="volumeLimite"
                  id="volumeLimite"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 grap-6">
          <div class="px-5 py-3 text-left sm:px-6">
            <router-link
              :to="`/centros`"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Voltar
            </router-link>
          </div>
          <div class="px-5 py-3 text-right sm:px-6">
            <button
              @click="save"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [
        { url: "/centros", value: "Centro de custos" },
        { url: "/centros/form", value: "Novo / Editar" },
      ],
      form: {
        nome: "",
        descricao: "",
        limiteMensal: {
          ativo: false, 
          volume: 0
        }
      },
    };
  },

  methods: {
    async save() {
      const method = this.form._id ? "put" : "post";
      const req = await this.$http[method](`/v1/centrocusto`, this.form);

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");
        this.$router.push({ path: `/centros` });
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
  },
  async beforeMount() {
    const id = this.$route.params.id;

    if (id) {
      const req = await this.$http.get(`/v1/centrocusto/${id}`);
      this.form = req.data;
    }
  },
};
</script>
